/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Divider,
  IconButton,
  AvatarGroup,
  Avatar as MUIAvatar,
  Skeleton,
  Grid,
} from '@mui/material';
import MyAvatar from 'components/Avatar';

import Eye from 'icons/Eye';
import RedTrash from 'icons/RedTrash';
import { Lightbox } from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import UserStatusLabel from 'sections/users/UserStatusLabel';
import BlockUnblockButton from 'sections/users/BlockUnblockButton';
import { UserDetailsResponse } from 'dto/user/user';
import UserVerifiedBadge from 'components/UserVerifiedBadge';
import { UserTypes } from 'dto/user';
import Image from 'components/image/Image';

interface Props {
  gender: string;
  name: string;
  Avatar: string | null;
  AvatarThumbnail: string | undefined;
  title: string;
  subTitle: string;
  isVerified: boolean;
  status: string;
  showControl: boolean;
  dependents: UserDetailsResponse[];
  data: any;
  actionFunctions: any;
  verifiedPercentage: number | null | undefined;
  checkStatus: string;
  isLoading: boolean;
}

export const UserCard2: FC<Partial<Props>> = ({
  gender = 'female',
  isVerified = true,
  name,
  subTitle,
  Avatar,
  AvatarThumbnail,
  title,
  showControl = true,
  dependents,
  data,
  actionFunctions,
  verifiedPercentage,
  checkStatus,
  isLoading,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Box>
      <Card
        component='div'
        sx={{
          width: '255px !important',
          height: 320,
          maxHeight: 320,
        }}
      >
        <Box
          sx={{
            height: '110px',
            backgroundColor: gender === 'female' ? '#82334F' : '#0B6F7D',
            position: 'relative',
          }}
        >
          {isLoading ? (
            <Skeleton
              variant='circular'
              sx={{
                height: '90px',
                width: '90px',
                fontSize: '90px',
                display: 'flex',
                placeItems: 'center',
                placeContent: 'center',
                position: 'absolute',
                bottom: '-50px',
                left: '50%',
                borderRadius: '50%',
                transform: 'translateX(-50%)',
                '& img': {
                  height: 'auto',
                },
              }}
            />
          ) : (
            <Box
              onClick={() => {
                if (Avatar != null && Avatar != '') {
                  setOpen(true);
                }
              }}
            >
              <Lightbox
                index={0}
                open={open}
                close={() => setOpen(false)}
                slides={[{ src: Avatar || '' }]}
                plugins={[Fullscreen, Slideshow, Zoom]}
              />

              <MyAvatar
                name={name || ''}
                isImage={!!Avatar}
                url={Avatar || ''}
                sx={{
                  height: '90px',
                  width: '90px',
                  fontSize: '90px',
                  display: 'flex',
                  placeItems: 'center',
                  placeContent: 'center',
                  position: 'absolute',
                  bottom: '-50px',
                  left: '50%',
                  borderRadius: '50%',
                  transform: 'translateX(-50%)',
                }}
              />
            </Box>
          )}
        </Box>
        <CardContent sx={{ mt: '40px' }}>
          {isLoading ? (
            <Skeleton
              sx={{
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            />
          ) : (
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
              color='text.secondary'
            >
              {title}
            </Typography>
          )}

          {isLoading ? (
            <Skeleton
              sx={{
                fontSize: '12px',
                textAlign: 'center',
                color: 'text.secondary',
                opacity: '50%',
              }}
            />
          ) : (
            <>
              {subTitle == '' || subTitle == null ? (
                <div style={{ height: 20 }}></div>
              ) : (
                <Typography
                  sx={{
                    fontSize: '12px',
                    textAlign: 'center',
                    color: 'text.secondary',
                    opacity: '50%',
                  }}
                >
                  {subTitle}
                </Typography>
              )}
            </>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 1,
              '& div': {
                border: 'none !important',
              },
            }}
          >
            <UserVerifiedBadge
              gender={gender}
              check_status={checkStatus as string}
              type={UserTypes.WALI}
              value={verifiedPercentage}
            />
          </Box>

          {showControl && (
            <>
              <Divider sx={{ my: 1 }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 1,
                  pt: 0.5,
                  alignItems: 'center',
                }}
              >
                <UserStatusLabel status={data.status} />
                {/* <Label color='success'>Active</Label> */}
                <Box
                  sx={{
                    display: 'flex',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      actionFunctions.deleteAction(data);
                    }}
                  >
                    <RedTrash sx={{ color: '#f44336' }} fontSize='small' />
                  </IconButton>

                  <BlockUnblockButton
                    userStatus={data.status}
                    onClick={() => {
                      actionFunctions.blockAction(data);
                    }}
                  />
                  {/* 
                  <IconButton
                    onClick={() => {
                      actionFunctions.blockAction(data);
                    }}
                  >
                    {data?.status == 'blocked' ? (
                      <LookGreen fontSize='small' />
                    ) : (
                      <LookOrange fontSize='small' />
                    )}
                  </IconButton> */}
                  <IconButton
                    onClick={() => {
                      actionFunctions.viewAction(data);
                    }}
                  >
                    <Eye sx={{ color: '#4caf50' }} fontSize='small' />
                  </IconButton>
                </Box>
              </Box>
            </>
          )}
          {!showControl && (
            <>
              {isLoading ? (
                <Grid
                  container
                  spacing={0}
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                >
                  {Array(3)
                    .fill(0)
                    .map((i) => (
                      <Grid item>
                        <Skeleton
                          key={`dependent-${i}`}
                          width={30}
                          height={30}
                          variant='circular'
                        />
                      </Grid>
                    ))}
                </Grid>
              ) : (
                <AvatarGroup
                  max={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 1,
                    '& div': {
                      border: 'none !important',
                    },
                  }}
                >
                  {dependents?.slice(0, 3).map((dependent) => (
                    <MUIAvatar
                      sx={{
                        height: '30px',
                        width: '30px',
                      }}
                      key={dependent.id}
                      src={
                        dependent.thumbnail_photo_url == null ? '' : dependent.thumbnail_photo_url
                      }
                      alt={dependent.full_name}
                    />
                  ))}
                </AvatarGroup>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
