import type { FC } from 'react';
import { memo } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Typography, Breadcrumbs, Link, Box, Button } from '@mui/material';
import Dashboard from 'icons/Dashboard';
import Dot from 'icons/Dot';
import useAuth from 'hooks/useAuth';

interface IBreads {
  text: string;
  url?: string;
}
interface Props {
  Breads: IBreads[];
  Header: string;
  perfix?: string;
  permission?: string | string[];
  addOneButton?: {
    link: string;
    text: string;
  };
}

const MyBreadcrumbs: FC<Props> = ({ Breads, Header, addOneButton, perfix, permission }) => {
  const auth = useAuth();

  return (
    <Box
      role='presentation'
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <Typography
          // variant='h1'
          sx={{
            color: 'primary.main',
            fontSize: '24px !important',
            fontWeight: 600,
            mb: 1,
          }}
        >
          {Header}
        </Typography>
        <Breadcrumbs
          aria-label='breadcrumb'
          separator={<Dot sx={{ color: 'rgba(255,255,255,0.6)', height: '16px', width: '16px' }} />}
        >
          <Link
            underline='hover'
            sx={{ display: 'flex', alignItems: 'center' }}
            color='inherit'
            href={perfix !== undefined ? perfix : '/dashboard/all'}
          >
            <Dashboard sx={{ mr: 0.5, color: 'primary.main' }} fontSize='inherit' />
            <Typography sx={{ display: 'flex', alignItems: 'center' }} color='text.primary'>
              Dashboard
            </Typography>
          </Link>
          {Breads.map((item, index) => {
            return (
              <Box key={`Breadcrumbs-${index}`} component={'span'}>
                {index + 1 !== Breads.length && (
                  <Link
                    underline='hover'
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color='inherit'
                    component={ReactRouterLink}
                    to={perfix !== undefined ? `${perfix}${item.url}` : `/dashboard${item.url}`}
                  >
                    <Typography sx={{ display: 'flex', alignItems: 'center' }} color='text.primary'>
                      {item.text}
                    </Typography>
                  </Link>
                )}
                {index + 1 === Breads.length && (
                  <Typography
                    sx={{ display: 'flex', alignItems: 'center', color: 'rgba(255,255,255,0.5)' }}
                  >
                    {item.text}
                  </Typography>
                )}
              </Box>
            );
          })}
        </Breadcrumbs>
      </Box>
      {addOneButton &&
        (permission != undefined
          ? auth.user?.permission_keys?.includes(permission as any)
          : true) && (
          <Link
            underline='none'
            component={ReactRouterLink}
            to={
              perfix !== undefined
                ? //@ts-ignore
                  `${perfix}/${addOneButton.link}`
                : //@ts-ignore
                  `/dashboard/${addOneButton.link}`
            }
          >
            <Button
              sx={{
                py: 1.5,
                px: {
                  sm: 5,
                  xs: 1.5,
                },
                borderRadius: '66666px',
              }}
              color='primary'
              variant='contained'
            >
              {/* @ts-ignore */}
              {addOneButton.text}
            </Button>
          </Link>
        )}
    </Box>
  );
};

export default memo(MyBreadcrumbs);
