import { Summary } from 'components/data-summary/summary';
import { FC } from 'react';
interface Props {
  BackendFilters: any[];
  values: any;
}

export const PhoneVerifiedUserSummary: FC<Props> = ({ values, BackendFilters }) => {
  return (
    <>
      <Summary
        FiltersValues={values}
        BackendFilters={BackendFilters}
        url={`dashboard/all-user/login-users-summary`}
        keyData={`data.data`}
        fields={[
          {
            label: 'Count',
            key: 'count',
          },
          {
            label: 'Get married',
            key: 'get_married_count',
          },
          {
            label: 'Wali',
            key: 'wali_count',
          },
          {
            label: 'Completed registration',
            key: 'completed_registeration_count',
          },
          {
            label: 'Not Completed registration',
            key: 'not_completed_registeration_count',
          },
        ]}
      />
    </>
  );
};
