import type { SyntheticEvent, FC, ReactNode } from 'react';
import { useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import MUITab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import type { Theme, SxProps } from '@mui/material';

interface Tab {
  label: string;
  value: any;
}

interface Props {
  tabs: Tab[];
  children: ReactNode;
  sxContainer?: SxProps<Theme>;
  setCurrentTab?: any;
  nameCurrentTable?: string;
  values?: any;
}

export const MyTabs: FC<Props> = ({
  tabs,
  children,
  sxContainer,
  setCurrentTab,
  nameCurrentTable,
  values,
}) => {
  const [value, setValue] = useState(tabs[0].value);

  const handleChange = (_event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const onChangeTab = useCallback((event: SyntheticEvent, newValue: string) => {
    if (setCurrentTab) {
      //@ts-ignore
      setCurrentTab({ ...values, [nameCurrentTable]: newValue });
    }
    handleChange(event, newValue);
  }, []);

  return (
    <Box sx={{ width: '100%', borderRadius: '12px', ...sxContainer }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            variant='scrollable'
            scrollButtons='auto'
            onChange={onChangeTab}
            sx={{
              background: '#292C2D',
              borderTopLeftRadius: '12px',
              borderTopRightRadius: '12px',
              px: 2,
              pt: 0.5,
            }}
          >
            {tabs.map((item) => {
              return (
                <MUITab
                  sx={{ fontSize: '14px', fontWeight: 400 }}
                  key={item.label}
                  label={item.label}
                  value={item.value}
                />
              );
            })}
          </TabList>
        </Box>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            borderBottomLeftRadius: '12px',
            borderBottomRightRadius: '12px',
          }}
        >
          {children}
        </Box>
      </TabContext>
    </Box>
  );
};
