import Label, { LabelColor } from 'components/Label';

export interface UserStatusLabelOption {
  status: string;
}

const UserStatusLabel = (props: UserStatusLabelOption) => {
  let myColor: LabelColor = 'success',
    label = null;
  switch (props.status) {
    case 'reported': {
      myColor = 'warning';
      label = 'Reported';
      break;
    }
    case 'disabled': {
      myColor = 'warning';
      label = 'Disabled';
      break;
    }
    case 'deactivated': {
      myColor = 'warning';
      label = 'Deactivated';
      break;
    }
    case 'blocked': {
      myColor = 'error';
      label = 'Blocked';
      break;
    }
    case 'deleted': {
      myColor = 'error';
      label = 'Deleted';
      break;
    }
    case 'pending_deletion': {
      myColor = 'warning';
      label = 'Pending Deletion';
      break;
    }
    case 'active': {
      myColor = 'success';
      label = 'Active';
      break;
    }
    default:
      break;
  }

  return <Label color={myColor}> {label} </Label>;
};

export default UserStatusLabel;
