import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Skeleton,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import {
  DatePickerFilterBackend,
  SelectFilterBackEnd,
  SwitchView,
  TextFilterBackend,
} from 'components/data-table/FilteresBackend';
import { getAllQuery } from 'helpers/query-string-url';
import { FC, Fragment, useMemo } from 'react';
import { useQuery } from 'react-query';
import axiosInstance, { baseUrl } from 'utils/axios';
import get from 'lodash/get';
import { getWindowHeight } from 'App';
interface BackendFilter {
  type: string;
  option: {
    label: string;
    value: string | number;
  }[];
  onChange: (params: any) => void;
  withAll?: boolean;
  values: any;
  name: any;
  placeholder: any;
  label: any;
  sx: SxProps<Theme>;
  icon?: any;
  disabled?: boolean;
}
interface Props {
  FiltersValues?: any;
  BackendFilters?: Partial<BackendFilter>[];
  url: string;
  keyData?: string;
  fields: { label: string; key: string }[];
}

export const Summary: FC<Props> = ({ FiltersValues, BackendFilters, url, keyData, fields }) => {
  const {
    data: dataQuery,
    isLoading,
    isFetched,
  } = useQuery(
    [{ ...FiltersValues, view: undefined }],
    () =>
      axiosInstance.get(`${baseUrl}/${url}`, {
        params: {
          ...FiltersValues,
          view: undefined,
          ...getAllQuery(),
        },
      }),
    {
      enabled: true,
      refetchInterval: 10000,
    }
  );
  const memoData = useMemo(() => {
    if (isFetched) {
      return get(dataQuery, keyData as string, undefined);
    } else if (dataQuery?.data?.data !== undefined) {
      return dataQuery?.data?.data;
    } else return {};
  }, [get(dataQuery, keyData as string, undefined), url]);
  return (
    <Box
      sx={{
        p: 3,
        background: (theme) => theme.palette.background.paper,
        borderRadius: '20px',
      }}
    >
      <Box
        sx={{
          pb: 3,
          gap: 3,
        }}
      >
        <Grid container spacing={3}>
          {BackendFilters?.map((item) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={item.name}>
                <Fragment key={`key-${item.name}`}>
                  {item.type === 'select' && (
                    <SelectFilterBackEnd
                      withAll={item.withAll}
                      label={item.label}
                      name={item.name}
                      options={item.option}
                      sx={item.sx}
                      setValues={(value: any) => {
                        (item as any).onChange(value);
                      }}
                      values={item.values}
                    />
                  )}
                  {item.type === 'text' && (
                    <TextFilterBackend
                      name={item.name}
                      setValues={(value: any) => {
                        (item as any).onChange(value);
                      }}
                      values={item.values}
                      placeholder={item.placeholder}
                      sx={item.sx}
                      label={item.label}
                    />
                  )}
                  {item.type === 'switch' && (
                    <SwitchView
                      name={item.name}
                      setValues={(value: any) => {
                        (item as any).onChange(value);
                      }}
                      values={item.values}
                    />
                  )}
                  {item.type === 'day-picker' && (
                    <DatePickerFilterBackend
                      name={item.name}
                      label={item.label}
                      placeholder={item.placeholder}
                      values={item.values}
                      setValues={(value: any) => {
                        (item as any).onChange(value);
                      }}
                    />
                  )}
                  {item.type === 'button' && (
                    <Button onClick={(item as any).onChange()} sx={item.sx}>
                      {item.label}
                    </Button>
                  )}
                  {item.type === 'icon-button' && (
                    <IconButton
                      disabled={item?.disabled}
                      onClick={() => (item as any).onChange()}
                      sx={item.sx}
                    >
                      {item?.icon}
                    </IconButton>
                  )}
                </Fragment>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      {isLoading === true && (
        <>
          {Array(29)
            .fill(0)
            .map((_, index) => (
              <Skeleton key={`loading-${index}`} />
            ))}
        </>
      )}
      {isLoading && (
        <>
          {Array(1)
            .fill(0)
            .map((_, index) => (
              <Skeleton key={`loading-${index}`} />
            ))}
        </>
      )}
      {!memoData && isLoading === false && (
        <Typography
          align='center'
          sx={{
            height: getWindowHeight() - 325,
            maxHeight: getWindowHeight() - 325,
          }}
        >
          there no data
        </Typography>
      )}
      {!isLoading && dataQuery?.data?.data && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Card sx={{ width: '100%', boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Grid container spacing={2}>
                {fields.map((item) => (
                  <Grid item xs={6} sm={4} md={3} key={item.key}>
                    <Typography variant='subtitle2' color='text.secondary'>
                      {item.label}
                    </Typography>
                    <Typography variant='body1' fontWeight='bold'>
                      {dataQuery?.data?.data[item.key] ?? 'N/A'}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
};
