/* eslint-disable @typescript-eslint/indent */
import { useState, type FC } from 'react';

import { MyTabs } from 'components/Tabs';
import { TabPanel } from '@mui/lab';
import { EmailEnteredUserList } from './list';
import { EmailEnteredUserSummary } from './summary';
import { getAllQuery } from 'helpers/query-string-url';

export const EmailEnteredUser: FC = () => {
  const [values, setValues] = useState<any>({
    email: '',
    unique_filter: 'unique-email',
    type: 'all',
    ...getAllQuery(),
  });
  const tabs = [
    {
      label: 'List',
      value: 'list',
    },
    {
      label: 'Summary',
      value: 'summary',
    },
  ];
  const BackendFilters = [
    {
      name: 'email',
      type: 'text',
      onChange: setValues,
      values: values,
      placeholder: 'Search Email………………',
    },
    {
      name: 'unique_filter',
      type: 'select',
      label: 'Email unique',
      onChange: setValues,
      values: values,
      placeholder: 'Email unique',
      option: [
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Unique Email',
          value: 'unique-email',
        },
      ],
    },
    {
      name: 'type',
      type: 'select',
      label: 'Type',
      onChange: setValues,
      values: values,
      placeholder: 'Type',
      option: [
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Get married',
          value: 'get_married',
        },
        {
          label: 'Wali',
          value: 'wali',
        },
      ],
    },
    {
      label: 'Start Date',
      placeholder: 'Start Day ...',
      name: 'start_date',
      type: 'day-picker',
      onChange: setValues,
      values: values,
      sx: {
        width: {
          xs: '100%',
        },
      },
    },
    {
      label: 'End Date',
      placeholder: 'End Day ...',
      name: 'end_date',
      type: 'day-picker',
      onChange: setValues,
      values: values,
      sx: {
        width: {
          xs: '100%',
        },
      },
    },
  ];
  return (
    <>
      <MyTabs tabs={tabs} nameCurrentTable={tabs[0].value}>
        <TabPanel
          sx={{
            padding: {
              md: 2,
              xs: 0.5,
            },
          }}
          value={tabs[0].value}
          key={tabs[0].value}
        >
          <EmailEnteredUserList values={values} BackendFilters={BackendFilters} />
        </TabPanel>
        <TabPanel
          sx={{
            padding: {
              md: 2,
              xs: 0.5,
            },
          }}
          value={tabs[1].value}
          key={tabs[1].value}
        >
          <EmailEnteredUserSummary values={values} BackendFilters={BackendFilters} />
        </TabPanel>
      </MyTabs>
    </>
  );
};
