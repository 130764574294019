import { createSvgIcon } from '@mui/material/utils';

const VerifiedFemale = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='22.5' height='22.5' viewBox='0 0 22.5 22.5'>
    <g id='verified_female' transform='translate(0.25 0.25)'>
      <rect
        id='Rectangle'
        width='22'
        height='22'
        rx='11'
        fill='rgba(237,78,134,0.5)'
        stroke='#E1764D'
        strokeWidth='0.5'
      />
      <g id='Group_7401' data-name='Group 7401' transform='translate(4.5 4.5)'>
        <rect id='Rectangle_2610' data-name='Rectangle 2610' width='13' height='13' fill='none' />
        <path
          id='Path_2390'
          data-name='Path 2390'
          d='M25.715,33.983c-.517-.517-.174-1.6-.439-2.238S24,30.552,24,29.849s1-1.237,1.277-1.9-.079-1.721.439-2.238,1.6-.174,2.238-.439S29.146,24,29.849,24s1.237,1,1.9,1.277,1.721-.079,2.238.439.174,1.6.439,2.238,1.277,1.192,1.277,1.9-1,1.237-1.277,1.9.079,1.721-.439,2.238-1.6.174-2.238.439-1.192,1.277-1.9,1.277-1.237-1-1.9-1.277S26.233,34.5,25.715,33.983Z'
          transform='translate(-23.275 -23.275)'
          fill='#E1764D'
        />
        <path
          id='Path_2391'
          data-name='Path 2391'
          d='M25.715,33.983c-.517-.517-.174-1.6-.439-2.238S24,30.552,24,29.849s1-1.237,1.277-1.9-.079-1.721.439-2.238,1.6-.174,2.238-.439S29.146,24,29.849,24s1.237,1,1.9,1.277,1.721-.079,2.238.439.174,1.6.439,2.238,1.277,1.192,1.277,1.9-1,1.237-1.277,1.9.079,1.721-.439,2.238-1.6.174-2.238.439-1.192,1.277-1.9,1.277-1.237-1-1.9-1.277S26.233,34.5,25.715,33.983Z'
          transform='translate(-23.275 -23.275)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.5'
        />
        <path
          id='Path_2392'
          data-name='Path 2392'
          d='M88.949,104l-3.3,3.15L84,105.575'
          transform='translate(-79.9 -98.776)'
          fill='none'
          stroke='#fff'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='0.5'
        />
      </g>
    </g>
  </svg>,
  'VerifiedFemale'
);

export default VerifiedFemale;
