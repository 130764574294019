import type { FC } from 'react';
import Label from 'components/Label';
import Verified from 'icons/Verified';
import VerifiedFemale from 'icons/VerifiedFemale';
import VerifiedWali from 'icons/VerifiedWali';

import { CheckUserStatuses, UserTypes } from 'dto/user';
export interface MySelectOption {
  value: any;
  label: string;
}

interface UserVerifiedBadgeProps {
  type: string;
  gender: string;
  value: number | string | null | undefined;
  check_status: string;
}

const UserVerifiedBadge: FC<UserVerifiedBadgeProps> = (props) => {
  let BGColor: any = null;

  let component = <></>;

  if (props.type == UserTypes.WALI) {
    BGColor = 'rgb(37, 162, 151)';
    if (props.check_status == CheckUserStatuses.APPROVED) {
      component = <VerifiedWali fontSize='small' />;
    }
  } else {
    BGColor = props.gender == 'male' ? 'secondary' : 'primary';
    const icon =
      props.gender == 'male' ? <Verified fontSize='small' /> : <VerifiedFemale fontSize='small' />;
    if (props.value == 100 && props.check_status == CheckUserStatuses.APPROVED) {
      component = icon;
    } else {
      component = (
        <Label
          color={BGColor}
          sx={{
            fontSize: '14px',
            borderColor: 'primary',
            borderWidth: '1px',
            borderStyle: 'solid',
          }}
        >
          {props.value || 0}%
        </Label>
      );
    }
  }

  return <>{component}</>;
};

export default UserVerifiedBadge;
