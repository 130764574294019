import { format, utcToZonedTime } from 'date-fns-tz';
export const makeTimeFormatDateFNSHelper = (
  date: string | Date | null | undefined,
  format_string: string = 'yyyy-MM-dd hh:mm:ss a'
) => {
  if (date == undefined || date == null || date == '') return '';
  const utcDate = utcToZonedTime(new Date(date || Date.now()), 'UTC');
  const time = format(new Date(utcDate), format_string);
  return time;
};
