import { Box, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material';
// import { getWindowHeight } from 'App';
import Scrollbar from 'components/Scrollbar';

import React from 'react';

type ContainerProps = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

const Container = (props: ContainerProps) => {
  // const height = getWindowHeight();
  return (
    <Scrollbar>
      <Box
        justifyContent='flex-start'
        alignItems='flex-start'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: 'background.default',
          color: 'text.primary',
          flex: 1,
          px: 2,
          py: 1,
          ...props?.sx,
          '& > *': {
            width: '100%',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: '16px',
          }}
          className='first-children'
        >
          {props.children}
        </Box>
        <Typography
          sx={{
            color: 'rgba(255,255,255,0.5)',
            mt: 1.5,
          }}
        >
          COPYRIGHT &copy; {new Date().getFullYear()} Vidnik. All rights reserved.
        </Typography>
      </Box>
    </Scrollbar>
  );
};

export default Container;
