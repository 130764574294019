import { IconButton, Tooltip, Box } from '@mui/material';
import LookGreen from 'icons/LookGreen';
import Look from 'icons/LookOrange';
import useAuth from 'hooks/useAuth';
import { permission } from 'config/permission';

export interface BlockUnblockButtonOption {
  userStatus: string;
  onClick: () => void;
}

const BlockUnblockButton = (props: BlockUnblockButtonOption) => {
  const auth = useAuth();

  const isDisabled =
    props.userStatus === 'deleted' ||
    !auth.user?.permission_keys.includes(permission.app_user_block_unblock_app_user);

  let icon = null;
  let message = '';
  if (props.userStatus == 'active') {
    icon = <Look fontSize='small' />;
  } else if (props.userStatus == 'blocked') {
    icon = <LookGreen fontSize='small' />;
  } else {
    icon = <Look fontSize='small' />;
  }
  if (!auth.user?.permission_keys.includes(permission.app_user_block_unblock_app_user))
    message = 'You don’t have permission to do that';
  if (props.userStatus === 'deleted') message = 'The user already deleted';

  return (
    <Tooltip title={message}>
      <Box>
        <IconButton
          disabled={isDisabled}
          onClick={() => {
            props.onClick();
          }}
          size='small'
        >
          {icon}
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default BlockUnblockButton;
