import { FC } from 'react';
import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
// import AuthGuard from './components/AuthGuard';
import DashboardLayout from 'components/layouts/DashboardLayout';
// import GuestGuard from './components/GuestGuard';
import LoadingScreen from 'components/LoadingScreen';
import AuthGuard from 'guards/AuthGuard';
import RedirectIfAuthGuard from 'guards/RedirectIfAuthGuard';
import RedirectToRegister from 'guards/RedirectToRegister';
import RedirectToLogin from 'guards/RedirectToLogin';
import PhoneVerifiedUsers from 'pages/users/phone-verified-users';
import PhoneEnteredUser from 'pages/users/phone-entered-users';
import EmailEnteredUsers from 'pages/users/email-entered-users';

const Loadable = (Component: FC) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages
const Login = Loadable(lazy(() => import('pages/authentication/Login')));
const Register = Loadable(lazy(() => import('pages/authentication/Register')));

const Dashboard = Loadable(lazy(() => import('pages/dashboard/Dashboard')));
const SuperAdminDashboard = Loadable(lazy(() => import('pages/super-admins-dashboard')));
const AdminLogs = Loadable(lazy(() => import('pages/admins-logs')));
const AdminReports = Loadable(lazy(() => import('pages/reports-admins')));
const AdminAllLogs = Loadable(lazy(() => import('pages/admins-all-logs')));

const SubAdmins = Loadable(lazy(() => import('pages/sub-admin')));
const CreateSubAdmins = Loadable(lazy(() => import('pages/sub-admin/CreateSubAdmin')));
const TechAdmins = Loadable(lazy(() => import('pages/tech-support-admin')));
const CreateTechAdmins = Loadable(lazy(() => import('pages/tech-support-admin/CreateTechAdmin')));
const SuperAdmins = Loadable(lazy(() => import('pages/super-admin')));
const CreateSuperAdmins = Loadable(lazy(() => import('pages/super-admin/CreateSuperAdmin')));
const AllAdmin = Loadable(lazy(() => import('pages/all-admin')));
const TeamPerformance = Loadable(lazy(() => import('pages/team-performance')));
const SystemStatusReport = Loadable(lazy(() => import('pages/system-status-report')));
const CSPerformanceReport = Loadable(lazy(() => import('pages/cs-performance-report')));

const Trending = Loadable(lazy(() => import('pages/trending')));

const MaleUser = Loadable(lazy(() => import('pages/users/male')));
const FemaleUser = Loadable(lazy(() => import('pages/users/female')));
const MaleUserBio = Loadable(lazy(() => import('pages/users-bio/male')));
const FemaleUserBio = Loadable(lazy(() => import('pages/users-bio/female')));
const SearchUser = Loadable(lazy(() => import('pages/users/registered-user')));
const DeleteUser = Loadable(lazy(() => import('pages/user-delete')));
const MatchUser = Loadable(lazy(() => import('pages/user-match')));
const MatchUserDashboard = Loadable(lazy(() => import('pages/user-match-dashboard')));
const MatchUserSummary = Loadable(lazy(() => import('pages/user-match-summary')));
const RecommendedUser = Loadable(lazy(() => import('pages/recommended-user')));
const Dependents = Loadable(lazy(() => import('pages/dependents')));

const AllSelfie = Loadable(lazy(() => import('pages/selfie/All')));
const RejectedSelfie = Loadable(lazy(() => import('pages/selfie/Rejected')));
const VerifiedSelfie = Loadable(lazy(() => import('pages/selfie/Verified')));
const WaitingSelfie = Loadable(lazy(() => import('pages/selfie/Waiting')));

const OneUser = Loadable(lazy(() => import('pages/user-details')));
const Wali = Loadable(lazy(() => import('pages/users/wali')));
const OneWali = Loadable(lazy(() => import('pages/wali-details')));
const RoleManagement = Loadable(lazy(() => import('pages/role-management')));
const CreateRole = Loadable(lazy(() => import('pages/role-management/CreateRole')));
const UpdateRolePage = Loadable(lazy(() => import('pages/role-management/UpdateRolePage')));

const SubscriptionDashboard = Loadable(lazy(() => import('pages/subscription-dashboard')));
const SubscriptionStatistics = Loadable(lazy(() => import('pages/subscription-statistics')));
const LandingSubscription = Loadable(lazy(() => import('pages/vidnik-landing-subscription')));
const LandingSubscriptionInvited = Loadable(lazy(() => import('pages/vidnik-landing-subscription-invited')));
const SubscriptionSummary = Loadable(lazy(() => import('pages/subscription-summary')));

const PaymentRefund = Loadable(lazy(() => import('pages/payment-refund')));

const DeactivateRequests = Loadable(lazy(() => import('pages/deactivate-requests')));
const DeactivateRequestsSummary = Loadable(lazy(() => import('pages/deactivate-requests/Summary')));

const Payments = Loadable(lazy(() => import('pages/wali-payments')));
const UserCoinsList = Loadable(lazy(() => import('pages/coins-list')));
const UserCoinsSummary = Loadable(lazy(() => import('pages/coins-summary')));
const Allflagged = Loadable(lazy(() => import('pages/all-flagged-photo')));
const AllUserReported = Loadable(lazy(() => import('pages/all-user-reported')));

const Messages = Loadable(lazy(() => import('pages/messages')));
const CreateMessages = Loadable(lazy(() => import('pages/create-message')));

const SystemSupportAllTickets = Loadable(lazy(() => import('pages/support-system/all-tickets')));
const SystemSupportMyTickets = Loadable(lazy(() => import('pages/support-system/my-tickets')));
const SystemSupportDashboard = Loadable(lazy(() => import('pages/support-system/dashboard')));
const SystemSupportCreateTicket = Loadable(
  lazy(() => import('pages/support-system/all-tickets/CreateTicket'))
);
const TicketDetailsPage = Loadable(lazy(() => import('pages/ticket-details/index')));

const Notifications = Loadable(lazy(() => import('pages/notifications')));
const CreateNotifications = Loadable(lazy(() => import('pages/notifications/CreateNotifications')));

const SendEmail = Loadable(lazy(() => import('pages/send-email')));
const CreateEmail = Loadable(lazy(() => import('pages/send-email/CreateEmail')));

const AppsSettings = Loadable(lazy(() => import('pages/apps-settings')));
const SuperAdminsAppsSettings = Loadable(lazy(() => import('pages/super-admin-app-settings')));

const EmailTemplated = Loadable(lazy(() => import('pages/email-templated')));
const Profile = Loadable(lazy(() => import('pages/profile')));

const DevEditor = Loadable(lazy(() => import('pages/email-dev-template')));
const EmailTemplateStyleFile = Loadable(lazy(() => import('pages/email-dev-template-style-file')));
const EmailTemplateHeaderFile = Loadable(
  lazy(() => import('pages/email-dev-template-header-file'))
);
const EmailTemplateFooterFile = Loadable(
  lazy(() => import('pages/email-dev-template-footer-file'))
);

const NotFound = Loadable(lazy(() => import('pages/not-found')));

const routes: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        path: 'login',
        element: (
          <RedirectIfAuthGuard>
            <Login />
          </RedirectIfAuthGuard>
        ),
      },
      {
        path: '/',
        element: (
          <RedirectIfAuthGuard>
            <Login />
          </RedirectIfAuthGuard>
        ),
      },
    ],
  },

  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Dashboard />,
      },

      {
        path: 'trending',
        element: <Trending />,
      },
      {
        path: 'male',
        element: <MaleUser />,
      },
      {
        path: 'female',
        element: <FemaleUser />,
      },
      {
        path: 'check-user-bio/male',
        element: <MaleUserBio />,
      },
      {
        path: 'check-user-bio/female',
        element: <FemaleUserBio />,
      },
      {
        path: 'registered-users',
        element: <SearchUser />,
      },
      {
        path: 'phone-verified-users',
        element: <PhoneVerifiedUsers />,
      },
      {
        path: 'phone-entered-users',
        element: <PhoneEnteredUser />,
      },
      {
        path: 'email-entered-users',
        element: <EmailEnteredUsers />,
      },
      {
        path: 'delete-users',
        element: <DeleteUser />,
      },
      {
        path: 'match-users/list',
        element: <MatchUser />,
      },
      {
        path: 'match-users/dashboard',
        element: <MatchUserDashboard />,
      },
      {
        path: 'match-users/summary',
        element: <MatchUserSummary />,
      },
      {
        path: 'recommended-user',
        element: <RecommendedUser />,
      },
      {
        path: 'dependents',
        element: <Dependents />,
      },
      {
        path: 'selfie/all',
        element: <AllSelfie />,
      },
      {
        path: 'selfie/waiting',
        element: <WaitingSelfie />,
      },
      {
        path: 'selfie/verified',
        element: <VerifiedSelfie />,
      },
      {
        path: 'selfie/rejected',
        element: <RejectedSelfie />,
      },
      {
        path: 'users/:id',
        element: <OneUser />,
      },
      {
        path: 'walis',
        element: <Wali />,
      },
      {
        path: 'walis/:id',
        element: <OneWali />,
      },
      {
        path: 'subscription/dashboard',
        element: <SubscriptionDashboard />,
      },
      {
        path: 'subscription/list',
        element: <SubscriptionStatistics />,
      },

      {
        path: 'subscription/summary',
        element: <SubscriptionSummary />,
      },
      {
        path: 'vidnik-subscriptions',
        element: <LandingSubscription />,
      },
      {
        path: 'vidnik-subscriptions/invited/:referral_code',
        element: <LandingSubscriptionInvited />,
      },
      {
        path: 'payment-refund',
        element: <PaymentRefund />,
      },
      {
        path: 'deactivate-requests/list',
        element: <DeactivateRequests />,
      },
      {
        path: 'deactivate-requests/summary',
        element: <DeactivateRequestsSummary />,
      },
      {
        path: 'vidnik-subscriptions',
        element: <LandingSubscription />,
      },

      {
        path: 'wali-payments',
        element: <Payments />,
      },
      {
        path: 'user-coins/list',
        element: <UserCoinsList />,
      },
      {
        path: 'user-coins/summary',
        element: <UserCoinsSummary />,
      },
      {
        path: 'all-flagged-photo',
        element: <Allflagged />,
      },
      {
        path: 'all-reported-users',
        element: <AllUserReported />,
      },
      {
        path: 'app-notifications',
        element: <Notifications />,
      },
      {
        path: 'app-notification/create-notifications',
        element: <CreateNotifications />,
      },
      {
        path: 'app-notification/edit-notifications/:id',
        element: <CreateNotifications />,
      },
      {
        path: 'messages',
        element: <Messages />,
      },
      {
        path: 'messages/create-messages',
        element: <CreateMessages />,
      },
      {
        path: 'messages/:id',
        element: <Messages />,
      },
      {
        path: 'send-email',
        element: <SendEmail />,
      },
      {
        path: 'send-email/send-new-email',
        element: <CreateEmail />,
      },
      {
        path: 'edit-email/:id',
        element: <CreateEmail />,
      },
      {
        path: 'app-settings',
        element: <AppsSettings />,
      },
      {
        path: 'email-templated',
        element: <EmailTemplated />,
      },
      {
        path: 'support-system/all-tickets',
        element: <SystemSupportAllTickets />,
      },
      {
        path: 'support-system/my-tickets/:status',
        element: <SystemSupportMyTickets />,
      },
      {
        path: 'support-system/dashboard',
        element: <SystemSupportDashboard />,
      },
      {
        path: 'support-system/ticket/create',
        element: <SystemSupportCreateTicket />,
      },
      {
        path: 'support-system/ticket/:id',
        element: <TicketDetailsPage />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

const SuperRoutes: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        path: 'login',
        element: (
          <RedirectIfAuthGuard>
            <RedirectToRegister>
              <Login />
            </RedirectToRegister>
          </RedirectIfAuthGuard>
        ),
      },
      {
        path: 'register',
        element: (
          <RedirectIfAuthGuard>
            <RedirectToLogin>
              <Register />
            </RedirectToLogin>
          </RedirectIfAuthGuard>
        ),
      },
      {
        path: '/',
        element: (
          <RedirectIfAuthGuard>
            <Login />
          </RedirectIfAuthGuard>
        ),
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <SuperAdminDashboard />,
      },
      {
        path: 'trending',
        element: <Trending />,
      },
      {
        path: 'users/:id',
        element: <OneUser />,
      },
      {
        path: 'sub-admin',
        element: <SubAdmins />,
      },
      {
        path: 'create-sub-admin',
        element: <CreateSubAdmins />,
      },
      {
        path: 'create-sub-admin/:id',
        element: <CreateSubAdmins />,
      },
      {
        path: 'tech-admin',
        element: <TechAdmins />,
      },
      {
        path: 'create-tech-admin',
        element: <CreateTechAdmins />,
      },
      {
        path: 'create-tech-admin/:id',
        element: <CreateTechAdmins />,
      },
      {
        path: 'super-admin',
        element: <SuperAdmins />,
      },
      {
        path: 'create-super-admin',
        element: <CreateSuperAdmins />,
      },
      {
        path: 'create-super-admin/:id',
        element: <CreateSuperAdmins />,
      },
      {
        path: 'admins-logs/:id',
        element: <AdminLogs />,
      },
      {
        path: 'admins-reports/:id',
        element: <AdminReports />,
      },
      {
        path: 'all-admins',
        element: <AllAdmin />,
      },
      {
        path: 'performance-report',
        element: <TeamPerformance />,
      },
      {
        path: 'system-status-report',
        element: <SystemStatusReport />,
      },
      {
        path: 'cs-performance-report',
        element: <CSPerformanceReport />,
      },
      {
        path: 'admins-all-logs',
        element: <AdminAllLogs />,
      },
      {
        path: 'app-settings',
        element: <SuperAdminsAppsSettings />,
      },
      {
        path: 'role-management',
        element: <RoleManagement />,
      },
      {
        path: 'role-management/create-role',
        element: <CreateRole />,
      },
      {
        path: 'role-management/update-role/:id',
        element: <UpdateRolePage />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

const DevRoutes: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        path: 'login',
        element: (
          <RedirectIfAuthGuard>
            <Login />
          </RedirectIfAuthGuard>
        ),
      },
      {
        path: '/',
        element: (
          <RedirectIfAuthGuard>
            <Login />
          </RedirectIfAuthGuard>
        ),
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'email-template-dev/style-file',
        element: <EmailTemplateStyleFile />,
      },
      {
        path: 'email-template-dev/header/:id',
        element: <EmailTemplateHeaderFile />,
      },
      {
        path: 'email-template-dev/footer/:id',
        element: <EmailTemplateFooterFile />,
      },
      {
        path: 'email-template-dev/footer/:id',
        element: <EmailTemplateFooterFile />,
      },
      {
        path: 'email-template-dev/:id',
        element: <DevEditor />,
      },
    ],
  },

  {
    path: '*',
    element: <NotFound />,
  },
];

export { routes, SuperRoutes, DevRoutes };
