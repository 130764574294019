/* eslint-disable @typescript-eslint/indent */
import type { FC } from 'react';
import { Fragment } from 'react';
import get from 'lodash/get';
import { useMemo, useReducer, useEffect } from 'react';
import {
  TablePagination,
  Skeleton,
  Box,
  SxProps,
  Theme,
  Typography,
  Button,
  IconButton,
  Grid,
} from '@mui/material';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect,
} from 'react-table';
import {
  TextFilterBackend,
  SelectFilterBackEnd,
  SwitchView,
  DatePickerFilterBackend,
  SelectPage,
} from './FilteresBackend';
import TableContainer from './TableContainer';
import CardsContainer from './TableCardsContainer';
import { useQuery } from 'react-query';
import axiosInstance, { baseUrl } from 'utils/axios';
import { SelectFilter, TextFilter } from './Filteres';
import { useSearchParams } from 'react-router-dom';
import { getAllQuery } from 'helpers/query-string-url';
import { getWindowHeight } from 'App';

interface BackendFilter {
  type: string;
  option: {
    label: string;
    value: string | number;
  }[];
  onChange: (params: any) => void;
  withAll?: boolean;
  values: any;
  name: any;
  placeholder: any;
  label: any;
  sx: SxProps<Theme>;
  icon?: any;
  disabled?: boolean;
}
interface Column {
  isSortable?: Boolean;
  Cell?: (props: any) => JSX.Element;
  Filter?: any;
  options?: any;
  isDummy?: Boolean;
  label?: string;
  Header?: string | ((props: any) => JSX.Element);
  accessor?: string;
  placeholder?: string;
  orderFilter?: number;
  sx?: SxProps<Theme>;
  sxHeader?: SxProps<Theme>;
}

interface Props {
  columns: Column[];
  data?: any;
  QueryKey?: any;
  url?: string;
  filterComeFromBackend?: boolean;
  BackendFilters?: Partial<BackendFilter>[];
  FiltersValues?: any;
  keyData?: string;
  setValues?: Function | undefined;
  actionFunctions?: any;
  setQueryKey?: any;
  rowStyle?: (row: any) => any;
  sxCard?: SxProps<Theme>;
}

const Table: FC<Props> = ({
  columns,
  data,
  QueryKey,
  url,
  BackendFilters,
  FiltersValues,
  keyData,
  actionFunctions,
  setQueryKey,
  sxCard,
  rowStyle = () => {
    return {};
  },
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params, updateParams] = useReducer(
    (state: any, values: any) => ({ ...state, ...values }),
    {
      limit: 10,
      page: 0,
      ...getAllQuery(),
    }
  );

  const backendSetPage = (page: number) => updateParams({ page });
  const backendSetPageSize = (size: number) => updateParams({ limit: size });

  const {
    data: dataQuery,
    isLoading,
    isFetched,
  } = useQuery(
    [QueryKey, params, { ...FiltersValues, view: undefined }],
    () =>
      axiosInstance.get(`${baseUrl}/${url}`, {
        params: {
          ...params,
          ...FiltersValues,
          view: undefined,
          ...getAllQuery(),
        },
      }),
    {
      enabled: data === undefined,
      refetchInterval: 10000,
    }
  );

  const memoData = useMemo(() => {
    if (isFetched) {
      return get(dataQuery, keyData as string, undefined);
    } else if (dataQuery?.data?.data !== undefined) {
      return dataQuery?.data?.data;
    } else if (data !== undefined) return data;
    else return [];
  }, [get(dataQuery, keyData as string, undefined), data, url]);

  useEffect(() => {
    if (setQueryKey) setQueryKey([QueryKey, params, FiltersValues]);
  }, [params, FiltersValues]);

  useEffect(() => {
    if (searchParams.get('page') == '0') backendSetPage(0);
  }, [searchParams.get('page')]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    //@ts-ignore
    page,
    //@ts-ignore
    gotoPage,
    //@ts-ignore
    setPageSize,
  } = useTable(
    {
      //@ts-ignore
      columns,
      data: memoData,
      initialState: {
        hiddenColumns: [],
        //@ts-ignore
        pageSize: searchParams.get('limit') || 10,
        // Filters: { id: 'content', value: text },
      },
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  if (data === undefined && memoData === undefined)
    return (
      <>
        {Array(15)
          .fill(0)
          .map((_, index) => (
            <Skeleton key={`loading-${index}`} />
          ))}
      </>
    );

  // Render the UI for your table
  return (
    <Box
      sx={{
        p: 3,
        background: (theme) => theme.palette.background.paper,
        borderRadius: '20px',
        ...sxCard,
      }}
    >
      <Box
        sx={{
          pb: 3,
          gap: 3,
        }}
      >
        <Grid container spacing={3}>
        {BackendFilters?.map((item) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={item.name}>
            <Fragment key={`key-${item.name}`}>
              {item.type === 'select' && (
                <SelectFilterBackEnd
                  withAll={item.withAll}
                  label={item.label}
                  name={item.name}
                  options={item.option}
                  sx={item.sx}
                  setValues={(value: any) => {
                    (item as any).onChange(value);
                  }}
                  values={item.values}
                />
              )}
              {item.type === 'text' && (
                <TextFilterBackend
                  name={item.name}
                  setValues={(value: any) => {
                    (item as any).onChange(value);
                  }}
                  values={item.values}
                  placeholder={item.placeholder}
                  sx={item.sx}
                  label={item.label}
                />
              )}
              {item.type === 'switch' && (
                <SwitchView
                  name={item.name}
                  setValues={(value: any) => {
                    (item as any).onChange(value);
                  }}
                  values={item.values}
                />
              )}
              {item.type === 'day-picker' && (
                <DatePickerFilterBackend
                  name={item.name}
                  label={item.label}
                  placeholder={item.placeholder}
                  values={item.values}
                  setValues={(value: any) => {
                    (item as any).onChange(value);
                  }}
                />
              )}
              {item.type === 'button' && (
                <Button onClick={(item as any).onChange()} sx={item.sx}>
                  {item.label}
                </Button>
              )}
              {item.type === 'icon-button' && (
                <IconButton
                  disabled={item?.disabled}
                  onClick={() => (item as any).onChange()}
                  sx={item.sx}
                >
                  {item?.icon}
                </IconButton>
              )}
            </Fragment>
            </Grid>
          );
        })}
      </Grid>
      </Box>
      {isLoading === true && (
        <>
          {Array(29)
            .fill(0)
            .map((_, index) => (
              <Skeleton key={`loading-${index}`} />
            ))}
        </>
      )}
      {memoData?.length === 0 && isLoading === false && (
        <Typography
          align='center'
          sx={{
            height: getWindowHeight() - 325,
            maxHeight: getWindowHeight() - 325,
          }}
        >
          there no data
        </Typography>
      )}
      {memoData?.length !== 0 &&
        isLoading === false &&
        (FiltersValues?.view === 'list' || FiltersValues?.view === undefined) && (
          <TableContainer
            page={page}
            prepareRow={prepareRow}
            headerGroups={headerGroups}
            getTableBodyProps={getTableBodyProps}
            getTableProps={getTableProps}
            rowStyle={rowStyle}
          />
        )}
      {memoData?.length !== 0 && isLoading === false && FiltersValues?.view === 'grid' && (
        <CardsContainer data={memoData} actionFunctions={actionFunctions} />
      )}
      {isLoading && (
        <>
          {Array(1)
            .fill(0)
            .map((_, index) => (
              <Skeleton key={`loading-${index}`} />
            ))}
        </>
      )}
      {!isLoading && dataQuery?.data?.data?.count >= 10 && (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <SelectPage
            page={Number(params.page)}
            onChange={(value) => {
              //@ts-ignore
              setSearchParams({
                ...getAllQuery(),
                page: Number(value),
              });
              backendSetPage(Number(value));
              gotoPage(Number(value));
            }}
            countPages={Math.floor(
              Number(dataQuery?.data?.data?.count || 1) / Number(params.limit || 1)
            )}
          />
          <TablePagination
            sx={{
              my: '10px',
              '& p': {
                marginBottom: 0,
              },
            }}
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            count={Number(dataQuery?.data?.data?.count)}
            rowsPerPage={Number(params.limit)}
            page={Number(params.page)}
            onPageChange={(v: unknown, MUInewPage: number) => {
              //@ts-ignore
              setSearchParams({
                ...getAllQuery(),
                page: Number(MUInewPage),
              });
              backendSetPage(Number(MUInewPage));
              gotoPage(Number(MUInewPage));
            }}
            onRowsPerPageChange={(e) => {
              let newPage = 0;
              try {
                const currentPage = Number(searchParams.get('page')) || 0;
                const currentLimit = Number(searchParams.get('limit')) || 10;
                const newLimit = Number(e.target.value);
                newPage = (currentPage * currentLimit) / newLimit;
                newPage = Math.floor(newPage);
              } catch {}
              //@ts-ignore
              setSearchParams({
                ...getAllQuery(),
                page: newPage,
                limit: Number(e.target.value),
              });
              backendSetPageSize(Number(e.target.value));
              setPageSize(Number(e.target.value));
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Table;

export { TextFilter, SelectFilter };
