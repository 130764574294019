import type { FC } from 'react';
import { Typography, Tooltip } from '@mui/material';
import type { Theme, SxProps } from '@mui/material';
// import { format } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
interface Props {
  sx?: SxProps<Theme>;
  formatValue?: string;
  value: string;
  formatToolTip?: string;
}

export const TextDate: FC<Props> = ({
  formatToolTip = 'PPpp',
  formatValue = 'yyyy/MM/dd',
  sx,
  value,
}) => {
  if (!!value) {
    const utcDate = utcToZonedTime(new Date(value || Date.now()), 'UTC');
    return (
      <Tooltip title={format(utcDate, formatToolTip)}>
        <Typography sx={sx}>{format(utcDate, formatValue)}</Typography>
      </Tooltip>
    );
  }
  return <></>;
};
