import type { FC } from 'react';
import { useState, useEffect, useRef } from 'react';
import type { Theme, SxProps } from '@mui/material';
import { TextField, InputAdornment, MenuItem, Box, IconButton, Typography } from '@mui/material';
import SearchIcon from 'icons/Search';
import List from 'icons/List';
import Grid from 'icons/Grid';
import { useSearchParams } from 'react-router-dom';
import { getAllQuery } from 'helpers/query-string-url';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { makeTimeFormatHelper } from 'helpers/moment.helper';

interface TextProps {
  setValues: (params: any) => void;
  values?: any;
  name: string;
  placeholder?: string;
  label?: string;
  sx?: SxProps<Theme>;
}

export const TextFilterBackend: FC<TextProps> = ({
  setValues,
  values,
  name,
  placeholder,
  label,
  sx,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isRender = useRef(false);
  const text = searchParams.get(name) == null ? '' : searchParams.get(name);
  const [state, setState] = useState(text);
  const params = getAllQuery();

  useEffect(() => {
    if (isRender.current) setValues({ ...values, ...getAllQuery(), [name]: state }); // Set undefined to remove the filter entirely
    isRender.current = true;
  }, [state]);

  return (
    <TextField
      value={state || ''}
      onChange={(e) => {
        setState(e.target.value);
        //@ts-ignore
        setSearchParams({ ...params, page: 0, [name]: e.target.value });
      }}
      placeholder={placeholder}
      label={label}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon fontSize='small' />
          </InputAdornment>
        ),
      }}
      sx={{
        '& .MuiInputBase-root': {
          borderRadius: '999px',
          ...(Boolean(values[name]) && {
            color: 'primary.main',
          }),
        },
        '& input': {
          border: 'none !important',
          outline: 'none',
        },
        WebkitBoxShadow: '0 0 0 30px #161819 inset !important',
        '& input:-webkit-autofill:hover': {
          WebkitBoxShadow: '0 0 0 30px #161819 inset !important',
          border: 'none !important',
          outline: 'none',
        },
        '& input:-webkit-autofill:active': {
          WebkitBoxShadow: '0 0 0 30px #161819 inset !important',
          border: 'none !important',
          outline: 'none',
        },
        '& input:-webkit-autofill:focus': {
          WebkitBoxShadow: '0 0 0 30px #161819 inset !important',
          border: 'none !important',
          outline: 'none',
        },
        ...sx,
      }}
    />
  );
};

interface SelectProps {
  setValues: (params: any) => void;
  values: any;
  name: string;
  label: string;
  sx?: SxProps<Theme>;
  withAll?: boolean;
  options?: {
    label: string;
    value: string | number;
  }[];
}

export const SelectFilterBackEnd: FC<SelectProps> = ({
  setValues,
  values,
  name,
  label,
  withAll,
  options,
  sx,
}) => {
  const setSearchParams = useSearchParams()[1];
  return (
    <TextField
      select
      defaultValue='all'
      label={label}
      value={values[name]}
      onChange={(e) => {
        setValues({ ...values, page: 0, [name]: e.target.value });
        //@ts-ignore
        setSearchParams({ ...getAllQuery(), page: 0, [name]: e.target.value });
      }}
      sx={{
        maxWidth: {
          md: '220px',
          xs: '100%',
        },
        width: '100%',
        '& .MuiInputBase-root': {
          borderRadius: '999px',
          color: 'primary.main',
        },
        WebkitBoxShadow: '0 0 0 30px #161819 inset !important',
        '& input:-webkit-autofill:hover': {
          WebkitBoxShadow: '0 0 0 30px #161819 inset !important',
        },
        '& input:-webkit-autofill:active': {
          WebkitBoxShadow: '0 0 0 30px #161819 inset !important',
        },
        '& input:-webkit-autofill:focus': {
          WebkitBoxShadow: '0 0 0 30px #161819 inset !important',
        },
        ...sx,
      }}
    >
      {withAll && <MenuItem value='all'>All</MenuItem>}
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

interface SwitchProps {
  setValues: (params: any) => void;
  values: any;
  name: string;
}

export const SwitchView: FC<SwitchProps> = ({ setValues, values, name }) => {
  const [, setSearchParams] = useSearchParams();
  const [viewType, setViewType] = useState(values[name]);
  const params = getAllQuery();

  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center' }}>
      <Box>
        <IconButton
          sx={{
            display: 'flex',
            flex: 1,
            width: '40px',
            height: '40px',
            background: (theme) => (viewType == 'list' ? theme.palette.primary.main : '#00000000'),
          }}
          onClick={() => {
            if (values?.view !== 'list') setValues({ ...values, view: 'list' });
            setViewType('list');

            setSearchParams({ ...params, [name]: 'list' });
          }}
        >
          <List />
        </IconButton>
      </Box>
      <Box>
        <IconButton
          sx={{
            display: 'flex',
            flex: 1,
            width: '40px',
            height: '40px',
            background: (theme) => (viewType == 'grid' ? theme.palette.primary.main : '#00000000'),
          }}
          onClick={() => {
            if (values?.view !== 'grid') setValues({ ...values, view: 'grid' });
            setViewType('grid');
            setSearchParams({ ...params, [name]: 'grid' });
          }}
        >
          <Grid />
        </IconButton>
      </Box>
    </Box>
  );
};

interface DatePickerProps {
  setValues: (params: any) => void;
  values?: any;
  name: string;
  placeholder?: string;
  label?: string;
}

export const DatePickerFilterBackend: FC<DatePickerProps> = ({
  setValues,
  values,
  name,
  placeholder,
  label,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isRender = useRef(false);
  const text = searchParams.get(name) == null ? '' : searchParams.get(name);
  const [state, setState] = useState(text);
  const params = getAllQuery();

  useEffect(() => {
    if (isRender.current) setValues({ ...values, ...getAllQuery(), [name]: state }); // Set undefined to remove the filter entirely
    isRender.current = true;
  }, [state]);

  return (
    <DesktopDatePicker
      label={label}
      value={state || ''}
      inputFormat='yyyy-MM-dd'
      onChange={(e: any) => {
        const day = makeTimeFormatHelper(e, 'yyyy-MM-DD');
        setState(day);
        //@ts-ignore
        setSearchParams({ ...params, page: 0, [name]: day });
      }}
      disableFuture
      renderInput={({ error, ...tProps }) => {
        return (
          <TextField
            sx={{
              '& .MuiInputBase-root': {
                borderRadius: '999px',
                color: 'primary.main',
              },
            }}
            required={false}
            placeholder={placeholder}
            error={false}
            fullWidth
            {...tProps}
          />
        );
      }}
    />
  );
};

interface PropsSelectPage {
  countPages: number;
  page: number;
  onChange: (page: number) => void;
}

export const SelectPage: FC<PropsSelectPage> = ({ countPages, onChange, page }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
      <Typography sx={{ fontSize:'0.875rem' }}>go to page:</Typography>
      <TextField
        select
        margin='dense'
        variant='standard'
        defaultValue={1}
        sx={{
          padding: 0,
        }}
        onChange={(e) => {
          onChange(Number(e.target.value));
        }}
        value={page}
        
        InputProps={{ disableUnderline: true }}
        SelectProps={{
        MenuProps:{
          sx:{
            height:310
          }
        }
        }}
      >
        {Array.from(Array(countPages + 1).keys()).map((item) => (
          <MenuItem value={item}>{item + 1}</MenuItem>
        ))}
      </TextField>
    </Box>
  );
};
