export enum UserGenders {
  MALE = 'male',
  FEMALE = 'female',
}

export enum UserStatuses {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  BLOCKED = 'blocked',
  REPORTED = 'reported',
  DELETED = 'deleted',
  PENDING_DELETION = 'pending_deletion',
}

export enum UserTypes {
  GET_MARRIED = 'get_married',
  WALI = 'wali',
}

export enum CheckUserStatuses {
  UN_CHECKED = 'un-checked',
  IN_PROGRESS = 'in-progress',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export const CheckUserStatusColors: any = {};
CheckUserStatusColors[CheckUserStatuses.UN_CHECKED] = 'warning';
CheckUserStatusColors[CheckUserStatuses.IN_PROGRESS] = 'warning';
CheckUserStatusColors[CheckUserStatuses.APPROVED] = 'success';
CheckUserStatusColors[CheckUserStatuses.REJECTED] = 'error';

export enum CheckUserStatusLabels {
  UN_CHECKED = 'Need Check',
  IN_PROGRESS = 'In Progress',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export const UserTypeColors: any = {};
UserTypeColors[UserGenders.MALE] = '#00C5E2';
UserTypeColors[UserGenders.FEMALE] = '#E1764D';
UserTypeColors[UserTypes.WALI] = 'rgb(37, 162, 151)';
UserTypeColors.guest = 'gray';

export const UserTypeLabels: any = {};
UserTypeLabels[UserGenders.MALE] = 'Male';
UserTypeLabels[UserGenders.FEMALE] = 'Female';
UserTypeLabels[UserTypes.WALI] = 'Wali';
UserTypeLabels[UserTypes.GET_MARRIED] = 'Get Married';
UserTypeLabels.guest = 'Guest';

export enum UserSelfieStatuses {
  WAITING = 'waiting',
  VERIFIED = 'verified',
  REJECTED = 'rejected',
  NOT_EXIST = 'not_exist',
}

export const UserSelfieStatusDisplay: any = {};

UserSelfieStatusDisplay[UserSelfieStatuses.WAITING] = {
  label: 'The Selfie is waiting for verification',
  color: 'warning',
};
UserSelfieStatusDisplay[UserSelfieStatuses.VERIFIED] = {
  label: 'Verified Selfie',
  color: 'success',
};
UserSelfieStatusDisplay[UserSelfieStatuses.REJECTED] = {
  label: 'Rejected Selfie',
  color: 'error',
};
UserSelfieStatusDisplay[UserSelfieStatuses.NOT_EXIST] = {
  label: 'There is no Selfie',
  color: 'secondary',
};
