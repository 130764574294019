import type { FC } from 'react';
import { Box, IconButton, Link, Tooltip } from '@mui/material';
import Eye from 'icons/Eye';
import { Link as RouterLink } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { permissionViewUserDetails } from 'helpers/admin-can-view-user-details.helper';

interface Props {
  to: string;
}

export const EyeViewUserDetails: FC<Props> = ({ to }) => {
  const auth = useAuth();
  if (permissionViewUserDetails(auth.user as any))
    return (
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Link component={RouterLink} to={to} underline='none'>
          <IconButton sx={{ color: '#4caf50' }} size='small'>
            <Eye fontSize='small' />
          </IconButton>
        </Link>
      </Box>
    );
  else
    return (
      <Tooltip title='You don’t have permission to do that'>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <IconButton disabled size='small'>
            <Eye fontSize='small' />
          </IconButton>
        </Box>
      </Tooltip>
    );
};
