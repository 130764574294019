import type { FC } from 'react';
import Container from 'components/container/Container';
import Breadcrumbs from 'components/Breadcrumbs';
import { PhoneEnteredUser } from 'sections/users/phone-entered-users';

const PhoneEnteredUsers: FC = () => {
  return (
    <Container>
      <Breadcrumbs
        Header='Step 2: Phone Entered Users'
        Breads={[
          {
            text: 'Step 2: Phone Entered Users',
          },
        ]}
      />
      <PhoneEnteredUser />
    </Container>
  );
};

export default PhoneEnteredUsers;
