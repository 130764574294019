import { useState } from 'react';
import type { Theme, SxProps } from '@mui/material';
import { TextField, InputAdornment, MenuItem } from '@mui/material';
import SearchIcon from 'icons/Search';

// eslint-disable-next-line @typescript-eslint/no-redeclare
interface Option {
  label: string;
  value: string | number;
}

interface Column {
  filterValue: any;
  setFilter: any;
  placeholder: any;
  sx?: SxProps<Theme>;
  options?: Option[];
}

interface Props {
  column: Column;
}

export const TextFilter = ({ column: { filterValue, setFilter, placeholder, sx } }: Props) => {
  return (
    <TextField
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={placeholder}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon fontSize='small' />
          </InputAdornment>
        ),
      }}
      sx={{
        '& .MuiInputBase-root': {
          borderRadius: '999px',
          ...(Boolean(filterValue) && {
            color: 'primary.main',
          }),
        },
        ...sx,
      }}
    />
  );
};

interface SelectProps {
  column: Column & { isDummy: Boolean; label: string };
}

export const SelectFilter = ({
  column: { filterValue, label, setFilter, options, isDummy = false, sx },
}: SelectProps) => {
  const [filter, setLocalFilter] = useState<any>();

  return (
    <TextField
      id='outlined-select-currency'
      select
      label={label}
      value={isDummy ? filter : filterValue}
      onChange={(e) => {
        if (isDummy) setLocalFilter(e.target.value || undefined);
        else setFilter(e.target.value || undefined);
      }}
      sx={{
        maxWidth: {
          md: '220px',
          xs: '100%',
        },
        width: '100%',
        '& .MuiInputBase-root': {
          borderRadius: '999px',
          color: 'primary.main',
        },
        ...sx,
      }}
    >
      <MenuItem value={''}>All</MenuItem>
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
