import { createSvgIcon } from '@mui/material/utils';

const List = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' id='list' width='23' height='23' viewBox='0 0 23 23'>
    <rect id='Rectangle_2586' data-name='Rectangle 2586' width='23' height='23' fill='none' />
    <line
      id='Line_447'
      data-name='Line 447'
      x2='17'
      transform='translate(3 12)'
      fill='#bfbfbf'
      stroke='#bfbfbf'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1'
    />
    <line
      id='Line_448'
      data-name='Line 448'
      x2='17'
      transform='translate(3 6)'
      fill='#bfbfbf'
      stroke='#bfbfbf'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1'
    />
    <line
      id='Line_449'
      data-name='Line 449'
      x2='17'
      transform='translate(3 18)'
      fill='#bfbfbf'
      stroke='#bfbfbf'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1'
    />
  </svg>,
  'List'
);

export default List;
