import type { FC, ReactNode } from 'react';
import type { Theme, SxProps } from '@mui/material';
import PropTypes from 'prop-types';
import { experimentalStyled, alpha } from '@mui/material/styles';

export type LabelColor =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';
type LabelVariant = 'filled' | 'outlined' | 'ghost';

interface LabelProps {
  children?: ReactNode;
  variant?: LabelVariant;
  color?: LabelColor;
  mycolor?: string;
  mybackgroundColor?: string;
  style?: {};
  sx?: SxProps<Theme>;
}

interface LabelRootProps {
  styleProps: {
    color: LabelColor | string | any;
  };
  variant: LabelVariant;
  backgroundColor?: string;
  color?: string;
  other?: any;
  isLight: Boolean;
}

const LabelRoot = experimentalStyled('span')<LabelRootProps>(
  ({ theme, styleProps, variant = 'ghost', isLight = false, color, backgroundColor }) => {
    let myBackGroundColor = '';
    let myColor = '';
    let border = '';

    if (variant === 'filled') {
      //@ts-ignore
      myBackGroundColor = theme.palette[styleProps.color].main;
      //@ts-ignore
      myColor = theme.palette[styleProps.color].contrastText;
    }

    if (variant === 'outlined') {
      //@ts-ignore
      myColor = theme.palette[styleProps.color].main;
      //@ts-ignore
      myBackGroundColor = 'transparent';
      //@ts-ignore
      border = `1px solid ${theme.palette[styleProps.color].main}`;
    }

    if (variant === 'ghost') {
      //@ts-ignore
      myColor = theme.palette[styleProps.color][isLight ? 'dark' : 'light'];
      //@ts-ignore
      myBackGroundColor = alpha(theme.palette[styleProps.color].main, 0.16);
    }
    if (backgroundColor) myBackGroundColor = backgroundColor;
    if (color) myColor = color;

    return {
      alignItems: 'center',
      backgroundColor: myBackGroundColor,
      borderRadius: theme.shape.borderRadius,
      color: myColor,
      cursor: 'default',
      display: 'inline-flex',
      flexGrow: 0,
      flexShrink: 0,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightMedium,
      justifyContent: 'center',
      letterSpacing: 0.5,
      minWidth: 10,
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(0.5),
      whiteSpace: 'nowrap',
      border: variant === 'outlined' ? border : 'unset',
    };
  }
);

const Label: FC<LabelProps> = (props) => {
  const { color = 'primary', children, ...other } = props;

  return (
    <>
      {/* @ts-ignore */}
      <LabelRoot
        styleProps={{
          color: color,
        }}
        color={props?.mycolor}
        backgroundColor={props?.mybackgroundColor}
        {...other}
      >
        {children}
      </LabelRoot>
    </>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'warning', 'success']),
};

export default Label;
