import { permission } from 'config/permission';
import { User } from 'types/user';

export const permissionViewUserDetails = (user: User) => {
  return (
    user.permission_keys.includes(permission.app_user_view_app_user_profile) ||
    user.permission_keys.includes(permission.app_user_view_app_user_match_activities) ||
    user.permission_keys.includes(permission.app_user_view_app_user_like_activities) ||
    user.permission_keys.includes(permission.app_user_view_app_user_reported) ||
    user.permission_keys.includes(permission.app_user_view_app_user_flagged_photos) ||
    user.permission_keys.includes(permission.app_user_view_app_user_subscriptions) ||
    user.is_super_admin
  );
};
