/* eslint-disable @typescript-eslint/indent */
import type { FC } from 'react';
import { useState } from 'react';

import { getAllQuery } from 'helpers/query-string-url';
import { MyTabs } from 'components/Tabs';
import { TabPanel } from '@mui/lab';
import { PhoneEnteredUserList } from './list';
import { PhoneEnteredUserSummary } from './summary';

export const PhoneEnteredUser: FC = () => {
  const [values, setValues] = useState<any>({
    email: '',
    phone_number: '',
    unique_filter: 'unique-email',
    type: 'all',
    ...getAllQuery(),
  });
  const BackendFilters = [
    {
      name: 'email',
      type: 'text',
      onChange: setValues,
      values: values,
      placeholder: 'Search Email………………',
    },
    {
      name: 'phone_number',
      type: 'text',
      onChange: setValues,
      values: values,
      placeholder: 'Search phone………………',
    },
    {
      name: 'unique_filter',
      type: 'select',
      label: 'User unique',
      onChange: setValues,
      values: values,
      placeholder: 'User unique',
      option: [
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Unique Email',
          value: 'unique-email',
        },
        {
          label: 'Unique Phone',
          value: 'unique-phone',
        },
        {
          label: 'Unique Email & Phone',
          value: 'unique-email-phone',
        },
      ],
    },
    {
      name: 'type',
      type: 'select',
      label: 'Type',
      onChange: setValues,
      values: values,
      placeholder: 'Type',
      option: [
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Get married',
          value: 'get_married',
        },
        {
          label: 'Wali',
          value: 'wali',
        },
      ],
    },
    {
      label: 'Start Date',
      placeholder: 'Start Day ...',
      name: 'start_date',
      type: 'day-picker',
      onChange: setValues,
      values: values,
      sx: {
        width: {
          xs: '100%',
        },
      },
    },
    {
      label: 'End Date',
      placeholder: 'End Day ...',
      name: 'end_date',
      type: 'day-picker',
      onChange: setValues,
      values: values,
      sx: {
        width: {
          xs: '100%',
        },
      },
    },
  ];
  const tabs = [
    {
      label: 'List',
      value: 'list',
    },
    {
      label: 'Summary',
      value: 'summary',
    },
  ];
  return (
    <>
      <MyTabs tabs={tabs} nameCurrentTable={tabs[0].value}>
              <TabPanel
                sx={{
                  padding: {
                    md: 2,
                    xs: 0.5,
                  },
                }}
                value={tabs[0].value}
                key={tabs[0].value}
              >
                <PhoneEnteredUserList values={values} BackendFilters={BackendFilters} />
              </TabPanel>
              <TabPanel
                sx={{
                  padding: {
                    md: 2,
                    xs: 0.5,
                  },
                }}
                value={tabs[1].value}
                key={tabs[1].value}
              >
                <PhoneEnteredUserSummary values={values} BackendFilters={BackendFilters} />
              </TabPanel>
            </MyTabs>
    </>
  );
};
