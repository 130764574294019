/* eslint-disable @typescript-eslint/indent */
import type { FC } from 'react';
import Table from 'components/data-table/Table';
import { useNavigate } from 'react-router-dom';

import UserTypeLabel from 'components/UserTypeLabel';
import { TextDate } from 'components/Date';
import { EyeViewUserDetails } from 'components/EyeViewUserDetails';
interface Props {
    values: any;
    BackendFilters: any[]
  }
export const PhoneVerifiedUserList: FC<Props> = ({ values, BackendFilters }) => {
  const navigate = useNavigate();

  return (
    <>
      <Table
        FiltersValues={values}
        keyData={`data.data.users`}
        url={`dashboard/all-user/login-users`}
        QueryKey={''}
        BackendFilters={BackendFilters}
        filterComeFromBackend={true}
        columns={[
          {
            Header: 'Order no',
            accessor: 'order_no',
          },
          {
            Header: '#User Id',
            accessor: 'id',
          },
          {
            Header: 'User Type',
            accessor: 'type',
            Cell: (props) => {
              return <UserTypeLabel type={props.value} gender={props.row.original.gender} />;
            },
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Phone',
            accessor: 'phone_number',
          },
          {
            Header: 'Register Type',
            accessor: 'register_type',
          },
          {
            Header: 'Joined',
            accessor: 'created_date',
            Cell: (props) => <TextDate value={props.value} />,
          },
          {
            Header: 'Email Verified',
            accessor: 'is_email_verified',
            Cell: (props) => (props.value ? <p>Verified</p> : <p>Not verified</p>),
          },
          {
            Header: 'Device id',
            accessor: 'device_id',
          },
          {
            Header: 'Device Type',
            accessor: 'device_type',
          },
          {
            Header: 'Email Enabled',
            Cell: (props) =>
              props.row.original.updates_emails_enabled &&
              props.row.original.marketing_emails_enabled ? (
                <p>Updates & Marketing</p>
              ) : props.row.original.updates_emails_enabled ? (
                <p>Updates</p>
              ) : props.row.original.marketing_emails_enabled ? (
                <p>Marketing</p>
              ) : (
                <p>Not enabled</p>
              ),
          },
          {
            Header: 'Email Notification',
            accessor: 'email_notification_status',
          },
          {
            Header: 'Push Notification',
            accessor: 'push_notification_status',
          },
          {
            accessor: 'action',
            Header: 'Action',
            Cell: (props) => {
              if (!props.row.original.is_completed_registration) return <></>;
              let url = 'users';
              if (props.row.original.type == 'wali') {
                url = 'walis';
              }
              return (
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <EyeViewUserDetails to={`/dashboard/${url}/${props.row.original.id}`} />
                </div>
              );
            },
          },
        ]}
        actionFunctions={{
          viewAction: (u: any) => {
            let url = 'users';
            if (u?.type == 'wali') {
              url = 'walis';
            }
            navigate(`/dashboard/${url}/${u?.id}`, { replace: true });
          },
        }}
      />
    </>
  );
};
