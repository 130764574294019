import type { FC } from 'react';
import Container from 'components/container/Container';
import Breadcrumbs from 'components/Breadcrumbs';
import { EmailEnteredUser } from 'sections/users/email-entered-users';

const EmailEnteredUsers: FC = () => {
  return (
    <Container>
      <Breadcrumbs
        Header='Step 1: Email Entered Users'
        Breads={[
          {
            text: 'Step 1: Email Entered Users',
          },
        ]}
      />
      <EmailEnteredUser />
    </Container>
  );
};

export default EmailEnteredUsers;
