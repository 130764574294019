import { createSvgIcon } from '@mui/material/utils';

const Grid = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' id='grid' width='23' height='23' viewBox='0 0 23 23'>
    <rect id='Rectangle_2587' data-name='Rectangle 2587' width='23' height='23' fill='none' />
    <path
      id='Path_2457'
      data-name='Path 2457'
      d='M51.78,51.78H44V44.741A.741.741,0,0,1,44.741,44H51.78Z'
      transform='translate(-39.925 -39.925)'
      fill='#bfbfbf'
      opacity='0'
    />
    <rect
      id='Rectangle_2588'
      data-name='Rectangle 2588'
      width='16'
      height='16'
      rx='2'
      transform='translate(4 4)'
      fill='none'
      stroke='#bfbfbf'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1'
    />
    <line
      id='Line_450'
      data-name='Line 450'
      y2='16'
      transform='translate(12 4)'
      fill='none'
      stroke='#bfbfbf'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1'
    />
    <line
      id='Line_451'
      data-name='Line 451'
      x1='16'
      transform='translate(4 12)'
      fill='none'
      stroke='#bfbfbf'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1'
    />
  </svg>,
  'Grid'
);

export default Grid;
