/* eslint-disable @typescript-eslint/no-unused-vars */
import type { FC } from 'react';
import { memo } from 'react';
import { Box } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import { UserCard2 } from 'components/cards/CardsUser';
import _ from 'lodash';
// import { getWindowHeight } from 'App';

interface Props {
  data: any[];
  actionFunctions: any;
}

const MyTableCardsContainer: FC<Props> = ({ data, actionFunctions }) => {
  return (
    <Box
      sx={{
        // height: getWindowHeight() - 315,
        // maxHeight: getWindowHeight() - 315,
      }}
    >
      <Scrollbar>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            gap: 4,
            flexWrap: 'wrap',
          }}
        >
          {data.map((item) => (
            <UserCard2
              key={item.id}
              isVerified={item.is_verified}
              gender={item.gender}
              subTitle={item.location}
              title={item.full_name}
              name={item.full_name}
              Avatar={item.photo_url}
              AvatarThumbnail={item.thumbnail_photo_url}
              data={item}
              verifiedPercentage={item.verified_percentage}
              actionFunctions={actionFunctions}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );
};

export default memo(MyTableCardsContainer, (prev, next) => _.isEqual(prev.data, next.data));
