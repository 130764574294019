import { createSvgIcon } from '@mui/material/utils';

const LookGreen = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='Group_7575'
    data-name='Group 7575'
    width='20'
    height='20'
    viewBox='0 0 20 20'
  >
    <path id='Path_2472' data-name='Path 2472' d='M0,0H20V20H0Z' fill='none' />
    <path
      id='Path_2473'
      data-name='Path 2473'
      d='M6.282,8.6H16.948a.823.823,0,0,1,.82.826v8.256a.823.823,0,0,1-.82.826H3.82A.823.823,0,0,1,3,17.686V9.43A.823.823,0,0,1,3.82,8.6h.82V7.779A5.738,5.738,0,0,1,15.522,5.194l-1.468.738A4.1,4.1,0,0,0,6.282,7.779Zm2.461,4.128v1.651h3.282V12.732Z'
      transform='translate(-0.384 -0.256)'
      fill='#4caf50'
    />
  </svg>,
  'LookGreen'
);

export default LookGreen;
