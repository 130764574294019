import { UserGenders, UserTypeColors, UserTypeLabels, UserTypes } from 'dto/user';
import type { FC } from 'react';
import Label from './Label';

export interface MySelectOption {
  value: any;
  label: string;
}

interface UserTypeLabelProps {
  type: string;
  gender: string;
  isGuest?: boolean;
}

const UserTypeLabel: FC<UserTypeLabelProps> = ({ isGuest = false, ...props }) => {
  let color: any = null,
    label = null;
  if (isGuest) {
    color = UserTypeColors.guest;
    label = UserTypeLabels.guest;
  } else {
    if (props.type == UserTypes.WALI) {
      color = UserTypeColors[UserTypes.WALI];
      label = UserTypeLabels[UserTypes.WALI];
    } else {
      if (!props.gender) {
        color = UserTypeColors[UserGenders.FEMALE];
        label = UserTypeLabels[UserTypes.GET_MARRIED];
      } else if (props.gender == UserGenders.MALE) {
        color = UserTypeColors[UserGenders.MALE];
        label = UserTypeLabels[UserGenders.MALE];
      } else {
        color = UserTypeColors[UserGenders.FEMALE];
        label = UserTypeLabels[UserGenders.FEMALE];
      }
    }
  }

  return <Label sx={{ background: '#000', color: color }}>{label}</Label>;
};

export default UserTypeLabel;
