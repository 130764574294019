import type { FC } from 'react';
import Container from 'components/container/Container';
import Breadcrumbs from 'components/Breadcrumbs';
import { PhoneVerifiedUser } from 'sections/users/phone-verified-users';

const PhoneVerifiedUsers: FC = () => {
  return (
    <Container>
      <Breadcrumbs
        Header='Step 3: Phone Verified Users'
        Breads={[
          {
            text: 'Step 3: Phone Verified Users',
          },
        ]}
      />
      <PhoneVerifiedUser />
    </Container>
  );
};

export default PhoneVerifiedUsers;
