import { Summary } from 'components/data-summary/summary';
import { FC } from 'react';
interface Props {
  BackendFilters: any[];
  values: any;
}

export const PhoneEnteredUserSummary: FC<Props> = ({ values, BackendFilters }) => {
  return (
    <>
      <Summary
        FiltersValues={values}
        BackendFilters={BackendFilters}
        url={`dashboard/all-user/temp-users-summary`}
        keyData={`data.data`}
        fields={
            [
                {
                    label: 'Count',
                    key: 'count',
                },
                {
                    label: 'Get married',
                    key: 'get_married_count',
                },
                {
                    label: 'Wali',
                    key: 'wali_count',
                },
            ]
        }
      />
    </>
  );
};
