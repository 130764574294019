import type { FC } from 'react';
import { memo, Fragment } from 'react';

import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  TableBody,
  TableSortLabel,
} from '@mui/material';
import _ from 'lodash';
import Scrollbar from 'components/Scrollbar';
// import { getWindowHeight } from 'App';

interface Props {
  getTableProps: any;
  headerGroups: any;
  getTableBodyProps: any;
  prepareRow: any;
  page: any;
  rowStyle?: (row: any) => any;
}

const MyContainerTable: FC<Props> = ({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  prepareRow,
  page,
  rowStyle = () => {
    return {};
  },
}) => {
  return (
    <TableContainer component={Scrollbar as any}>
      <Table stickyHeader aria-label='sticky table' {...getTableProps()}>
        <TableHead
          sx={{
            backgroundColor: '#292C2D',
          }}
        >
          {headerGroups.map((headerGroup: any) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any, index: any) => (
                <Fragment key={`table-key-${index}`}>
                  {!column.isSortable && (
                    <TableCell
                      {...column.getHeaderProps()}
                      sx={{
                        borderBottom: 'none',
                        backgroundColor: '#292C2D',
                        ...(index === 0 && {
                          borderTopLeftRadius: '6666666px',
                          borderBottomLeftRadius: '6666666px',
                          borderBottom: 'none',
                        }),
                        ...(index === headerGroup.headers.length - 1 && {
                          borderTopRightRadius: '6666666px',
                          borderBottomRightRadius: '6666666px',
                          borderBottom: 'none',
                        }),
                        whiteSpace: 'nowrap',
                        ...column?.sxHeader,
                      }}
                    >
                      {column.render('Header')}
                    </TableCell>
                  )}
                  {column.isSortable && (
                    <TableCell
                      {...column.getHeaderProps()}
                      sortDirection={column.isSorted}
                      sx={{
                        borderBottom: 'none',
                        backgroundColor: '#292C2D',
                        ...(index === 0 && {
                          borderTopLeftRadius: '6666666px',
                          borderBottomLeftRadius: '6666666px',
                          borderBottom: 'none',
                        }),
                        ...(index === headerGroup.headers.length - 1 && {
                          borderTopRightRadius: '6666666px',
                          borderBottomRightRadius: '6666666px',
                          borderBottom: 'none',
                        }),
                        whiteSpace: 'nowrap',
                        ...column?.sxHeader,
                      }}
                    >
                      <TableSortLabel
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                        active={column.isSorted}
                        {...column.getSortByToggleProps()}
                      >
                        {column.render('Header')}
                      </TableSortLabel>
                    </TableCell>
                  )}
                </Fragment>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row);

            return (
              <TableRow {...row.getRowProps()} style={{ ...rowStyle(row.original) }}>
                {row.cells.map((cell: any) => {
                  return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(MyContainerTable, (prev, next) => _.isEqual(prev.page, next.page));
