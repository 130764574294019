import { createSvgIcon } from '@mui/material/utils';

const RedTrash = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' id='delete' width='20' height='20' viewBox='0 0 20 20'>
    <path id='Path_2468' data-name='Path 2468' d='M0,0H20V20H0Z' fill='none' />
    <path
      id='Path_2469'
      data-name='Path 2469'
      d='M16.4,6V17.2a.8.8,0,0,1-.8.8H4.4a.8.8,0,0,1-.8-.8V6H2V4.4H18V6ZM9.2,8.4V14h1.6V8.4ZM6,2h8V3.6H6Z'
      fill='currentColor'
    />
  </svg>,
  'RedTrash'
);

export default RedTrash;
